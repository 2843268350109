<template>
  <div class="">
    <v-tabs
      v-model="tab"
      color="primary"
      grow
    >
      <v-tab key="cb">
        BDs
      </v-tab>
      <v-tab key="mc">
        Mini collectors (MC)
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        key="cb"
        class="pa-0 pb-16"
      >
        <v-card
          elevation="0"
          :loading="loader.catalog"
        >
          <banner></banner>
        </v-card>
        <div
          v-if="loader.reload"
          class="pa-lg-6 pa-sm-3 text-center"
        >
          <v-btn
            class="ma-auto"
            color="primary"
            @click="init()"
          >
            Recharger
          </v-btn>
        </div>
        <div
          v-else
          class="pa-lg-6 pa-sm-3"
        >
          <v-row no-gutters v-if="loader.catalog">
            <v-col
              v-for="n in 6"
              :key="'new-'+n"
              class="pa-1 pa-lg-3 pa-md-3"
              cols="4"
              lg="3"
              md="3"
            >
              <v-sheet
                :color="`grey darken-2 `"
                width="100%!important"
              >
                <v-skeleton-loader
                  width="100%!important"
                  class="d-inline-block mr-lg-2 ml-lg-2 mr-sm-1 ml-sm-1 mt-3"
                  type="card"
                ></v-skeleton-loader>
              </v-sheet>
            </v-col>
          </v-row>

          <v-row no-gutters v-else>
            <v-col
              v-for="item in comicbooks"
              :key="'cb-'+item.id"
              class="pa-1 pa-lg-3 pa-md-3"
              cols="4"
              lg="3"
              md="3"
            >
              <v-card
                elevation="0"
                class="d-inline-block mr-lg-2 ml-lg-2 mr-sm-1 ml-sm-1 mt-3"
                :to="'/comicbook/'+item.nameseo+'/'+item.id"
                @click="detail(item)"
              >
                <v-img
                  max-height="300"
                  :src="item.cover_src"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0 "
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-card-text class="title-inline ma-0 pa-0 mb-0 pb-0">
                  <b>{{ item.name }}</b>
                </v-card-text>
                <v-card-subtitle class="mt-0 pa-0">
                  {{ item.nbchapter }} chapitres
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-tab-item>
      <v-tab-item
        key="mc"
      >
        list des mcs
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import {computed, ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import {getVuetify} from '@/plugins/utils'
import Banner from '@/components/Banner'
// import { useMeta } from 'vue-meta'

export default {
  components: {Banner},
  setup() {
    //useMeta({ title: 'Some Page' })
    const $vuetify = getVuetify()
    const loader = ref({
      catalog: true,
      reload: false,
    })
    const items = ref([])
    const drawer = ref(false)
    const tab = ref('cb')
    const comicbooks = ref([])
    const comicbook = ref({})
    const columns = computed(() => {
      if ($vuetify.breakpoint.md) {
        return '20%'
      }
      if ($vuetify.breakpoint.sm) {
        return '31%'
      }
      if ($vuetify.breakpoint.xs) {
        return '31%'
      }

      return '13%'
    })
    const init = () => {
      loader.value.reload = false
      loader.value.catalog = true
      Drequest.api('lazyloading.comicbook?dfilters=on&status._key:eq=process&lang=fr')
        .param({
          next: 1,
          per_page: 15,
          //'cover:isNull': 0,
          //'banner:isNull': 0,
          dsort: "id desc",
        })
        .get(response => {
          console.log(response)
          comicbooks.value = response.listEntity
          loader.value.catalog = false
        })
        .fail(e => {
          loader.value.reload = true
        })
    }
    init()
    const detail = cb => {
      drawer.value = true
      comicbook.value = cb
    }

    return {
      detail,

      loader,
      tab,
      items,
      comicbooks,
      columns,
      drawer,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
